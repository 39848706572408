import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';

@Component({
  selector: 'app-image-attachment-modal',
  templateUrl: './image-attachment-modal.component.html',
  styles: [
    `
      .new-modal {
        min-width: initial;
      }
    `
  ]
})
export class ImageAttachmentModalComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ImageAttachmentModalComponent>,
    private fb: FormBuilder
  ) {}

  get isImage(): boolean {
    return this.form.get('asImage').value;
  }

  get isAttachment(): boolean {
    return this.form.get('asAttachment').value;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      asImage: false,
      asAttachment: false
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.dialogRef.close(this.form.getRawValue());
  }
}
