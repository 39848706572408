<div class="new-modal">
  <div class="title__wrapper">
    <span class="title">{{ 'PasteImageDialog.title' | translate }}</span>
    <svg-icon
      class="clear-icon ml-2 align-self-start"
      src="/assets/img/close-icon.svg"
      (click)="onCancel()"
    ></svg-icon>
  </div>

  <div class="new-modal__content px-2">
    <div class="d-flex flex-column">
      <checkbox
        [form]="form"
        controlName="asImage"
        [title]="'PasteImageDialog.asImage' | translate"
      ></checkbox>
      <checkbox
        class="mt-2"
        [form]="form"
        controlName="asAttachment"
        [title]="'PasteImageDialog.asAttachment' | translate"
      ></checkbox>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <app-button
      (onClick)="onCancel()"
      [config]="{
        text: 'cancel' | translate,
        type: ButtonTypes.NEW_SECONDARY_NO_BORDER_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>

    <app-button
      [class]="'ml-2'"
      [disabled]="!isImage && !isAttachment"
      (onClick)="onSubmit()"
      [config]="{
        text: 'confirm' | translate,
        type: ButtonTypes.NEW_PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        smSize: ButtonSize.SMALL
      }"
    ></app-button>
  </div>
</div>
